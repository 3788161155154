import React, { Fragment } from 'react';
import { Normalize } from 'styled-normalize';

import { IntlProvider } from 'react-intl';

import { BrowserRouter } from 'react-router-dom';
import en from './i18n/en-EN';
import RouterWrapper from './containers/RouterWrapper';

const App = () => (
  <IntlProvider locale="en" messages={en}>
    <Fragment>
      <Normalize />
      <BrowserRouter>
        <RouterWrapper />
      </BrowserRouter>
    </Fragment>
  </IntlProvider>
);

export default App;
