import styled from 'styled-components';

const Logo = styled.img`
  width: 81px;
  height: auto;

  @media (min-width: 768px) {
    width: 96px;
  }
`;

export default Logo;
