import React from 'react';
import styled from 'styled-components';

import { Link, FlagGreece, FlagFrance, FlagPortugal, FlagSpain, FlagItaly } from 'components/index';

const LinkWrapper = styled.div`
  display: inline-flex;
  align-items: flex-end;

  ${Link} {
    margin-left: 0.625rem;
  }

  @media (max-width: 568px) {
    display: flex;
    flex-direction: row;
    width: 50%;
    margin-bottom: 43px;

    &:last-child {
      width: 100%;
    }
  }
}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: 569px) {
    display: inline-block;

    ${LinkWrapper} {
      margin-bottom: 20px;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const sites = [
  {
    Flag: FlagItaly,
    label: 'uala.it',
    href: 'http://www.uala.it',
  },
  {
    Flag: FlagSpain,
    label: 'uala.es',
    href: 'http://www.uala.es',
  },
  {
    Flag: FlagPortugal,
    label: 'uala.pt',
    href: 'http://www.uala.pt',
  },
  {
    Flag: FlagFrance,
    label: 'uala.fr',
    href: 'http://www.uala.fr',
  },
  {
    Flag: FlagGreece,
    label: 'uala.gr',
    href: 'http://www.uala.gr',
  },
];

const Links = () => (
  <Container>
    {sites.map(({ Flag, label, href }) => (
      <LinkWrapper key={label}>
        <Flag />
        <Link href={href}>{label}</Link>
      </LinkWrapper>
    ))}
  </Container>
);

export default Links;
