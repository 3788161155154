import React, { useEffect } from 'react';
import Main from '../../containers/Main';
import WomanImage from '../../components/WomanImage';
import TermsContent from '../../containers/TermsContent';

const Terms = () => {
  useEffect(() => {
    document.title = 'UALA: Terms and conditions';
  }, []);

  return (
    <Main>
      <WomanImage />
      <TermsContent />
    </Main>
  );
};

export default Terms;
