import styled from 'styled-components';

const Link = styled.a`
  text-decoration: none;
  font-size: 18px;
  color: #000000;
  border-bottom: 6px solid #ffea30;
  line-height: 0.8rem;

  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
    color: #000000;
  }
`;

export default Link;
