import React, { useEffect } from 'react';
import Main from '../../containers/Main';
import WomanImage from '../../components/WomanImage';
import LandingContent from '../../containers/LandingContent';

const Landing = () => {
  useEffect(() => {
    document.title = 'UALA: Leading marketplace portals in multiple countries in Europe';
  }, []);

  return (
    <Main>
      <WomanImage />
      <LandingContent />
    </Main>
  );
};

export default Landing;
