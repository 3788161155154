import React from 'react';
import styled from 'styled-components';

import { Link, FlagGreece, FlagFrance, FlagPortugal, FlagSpain, FlagItaly } from 'components/index';

const LinksWrapper = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 35px;

  > a {
    display: inline-flex;
    margin: 0 0 0 10px;
  }
`;

const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const sites = [
  {
    Flag: FlagItaly,
    countryCode: 'it',
    privacy: 'http://www.uala.it/privacy',
    terms: 'http://www.uala.it/terms',
  },
  {
    Flag: FlagSpain,
    countryCode: 'es',
    privacy: 'http://www.uala.es/privacy',
    terms: 'http://www.uala.es/terms',
  },
  {
    Flag: FlagPortugal,
    countryCode: 'pt',
    privacy: 'http://www.uala.pt/privacy',
    terms: 'http://www.uala.pt/terms',
  },
  {
    Flag: FlagFrance,
    countryCode: 'fr',
    privacy: 'http://www.uala.fr/privacy',
    terms: 'http://www.uala.fr/terms',
  },
  {
    Flag: FlagGreece,
    countryCode: 'gr',
    privacy: 'http://www.uala.gr/privacy',
    terms: 'http://www.uala.gr/terms',
  },
];

const ExtendedLinks = props => (
  <Container>
    {sites.map(({ Flag, label, countryCode, terms, privacy }) => (
      <LinksWrapper key={label}>
        <Flag />
        <Link href={props.route === 'terms' ? terms : privacy}>{`uala.${countryCode}/${props.route}`}</Link>
      </LinksWrapper>
    ))}
  </Container>
);

export default ExtendedLinks;
