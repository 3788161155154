import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as routes from '../../shared/routes';
import Landing from '../../routes/Landing';
import PrivacyPolicy from '../../routes/PrivacyPolicy';
import Terms from '../../routes/Terms';

const RouterWrapper = () => {
  return (
    <Switch>
      <Route exact path={routes.LANDING} component={Landing} />
      <Route exact path={routes.PRIVACY_POLICY} component={PrivacyPolicy} />
      <Route exact path={routes.TERMS} component={Terms} />
      <Redirect to={routes.LANDING} />
    </Switch>
  );
};

export default RouterWrapper;
