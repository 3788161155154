const translations = {
  'landing.heading': 'Discover & Book your beauty appointment',
  'privacyPolicy.heading': 'Privacy Policy',
  'terms.heading': 'Terms and conditions',
  'landing.privacy': 'Privacy Policy',
};

export default {
  ...translations,
};
