import styled from 'styled-components';
import sprite from 'assets/flags-sprite.png';

const Flag = styled.div`
  width: 22px;
  height: 22px;
  background: url("${sprite}");
  background-size: auto 22px;
  display: inline-block;
`;

const FlagItaly = styled(Flag)`
  background-position: 0 0;
`;

const FlagSpain = styled(Flag)`
  background-position: -38px 0;
`;

const FlagPortugal = styled(Flag)`
  background-position: -76px 0;
`;

const FlagGreece = styled(Flag)`
  background-position: -114px 0;
`;

const FlagFrance = styled(Flag)`
  background-position: -152px 0;
`;

export { FlagItaly, FlagSpain, FlagPortugal, FlagFrance, FlagGreece };
