import React from 'react';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { Logo } from 'components';
import logoImg from 'assets/logo.png';

import Links from '../../components/Links';

const PRIVACY_LINK = 'https://www.uala.com/privacy-policy';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 40px 20px;

  @media (min-width: 706px) {
    justify-content: center;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  height: 60%;
  max-height: 550px;

  @media (min-width: 768px) {
    align-items: center;
  }

  @media (min-width: 992px) {
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const Heading = styled.div`
  max-width: 800px;
  padding: 60px 0;

  h1 {
    font-size: 2rem;
    font-weight: 300;
  }

  @media (min-width: 706px) and (min-height: 300px) {
    padding: 20px 0;
  }

  @media (min-height: 480px) and (min-width: 905px) {
    h1 {
      font-size: 3.5rem;
    }
  }
`;

const PrivacyLink = styled.a`
  position: absolute;
  right: 20px;
  bottom: 40px;
  text-decoration: none;
  font-size: 18px;
  color: #000000;
  border-bottom: 3px solid #ffea30;
  line-height: 0.8rem;

  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
    color: #000000;
  }

  @media (min-width: 768px) {
    right: 32px;
    bottom: 32px;
  }
`;

const LandingContent = () => (
  <Container>
    <Inner>
      <Logo alt="Uala Logo" src={logoImg} />
      <Heading>
        <h1>
          <FormattedMessage id="landing.heading" />
        </h1>
      </Heading>
      <Links />
    </Inner>
    <PrivacyLink href={PRIVACY_LINK} target="_blank">
      <FormattedMessage id="landing.privacy" />
    </PrivacyLink>
  </Container>
);

export default LandingContent;
