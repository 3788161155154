import styled from 'styled-components';

const Main = styled.main`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: 'Cera Pro', sans-serif;
  font-size: 16px;

  @media (min-width: 569px) and (min-height: 300px) {
    overflow: hidden;
    height: 100vh;
  }
`;

export default Main;
