import React, { useEffect } from 'react';
import Main from '../../containers/Main';
import WomanImage from '../../components/WomanImage';
import PrivacyPolicyContent from '../../containers/PrivacyPolicyContent';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = 'UALA: Privacy Policy';
  }, []);

  return (
    <Main>
      <WomanImage />
      <PrivacyPolicyContent />
    </Main>
  );
};

export default PrivacyPolicy;
