import React from 'react';

import styled from 'styled-components';
import sectionBg from 'assets/woman-bg.png';

const ImgWrapper = styled.div`
  display: none;

  @media (min-width: 992px) and (min-height: 300px) {
    display: block;
    height: 100%;

    img {
      height: 100%;
      width: auto;
    }
  }
`;

const WomanImage = () => (
  <ImgWrapper>
    <img src={sectionBg} alt="Uala woman beauty appointments" />
  </ImgWrapper>
);

export default WomanImage;
